<template>
  <div class="box1">
    <el-container>
<!--      <el-header>
    <div><img src="@/assets/login.png"></div></el-header>-->
      <el-main>
    <div class="login-container">
      <h1 style="color: white">DHL物流管理系统</h1>
      <ul>
        <li>
          <label style="color: white">用户：</label>
          <input type="text" placeholder="请输入用户名" v-model.trim="username">
        </li>
        <li>
          <label style="color: white">密码：</label>
          <input type="password" placeholder="请输入密码" v-model.trim="password">
        </li>
        <li class="button-container">
          <el-button type="primary" @click="sign">登录</el-button>
        </li>
        <li v-show="flag" :class="{ 'tip-style': flag }">
          {{ this.tip }}
        </li>
      </ul>
    </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
export default {
  name:'LoginForm',
  // 响应式数据 data() 函数
  data(){
    return{
      username:'',
      password:'',
      tip:'',
      // 设置标识
      flag:'false',
    }
  },
  created() {
    // eslint-disable-next-line no-debugger
    debugger
  },
  // 方法书写在 methods 中
  methods:{
    getLogin(val){
      let this_=this;
      let data ={
        "account":this.username,
        "password":this.password
      }
      this.jquery.ajax({
        url: this.$BASE_URL + '/dhl_logistics_applet/manager/order/login',
        method: 'POST',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
        async: false, // 设置成同步请求
        data: JSON.stringify(data),
        success: function(response) {
          // 请求成功的处理逻辑
          if (response.code==200){
            this_.$wlglUser.userName=response.data.account;
            this_.$wlglUser.realName=response.data.username;
            this_.$wlglUser.status=response.data.status;
            sessionStorage.setItem('token',"完成")
            // 跳转页面
            this_.$router.push('/home')
            // eslint-disable-next-line no-empty
          } else {
            this_.tip=response.msg;
          }
        },
        error: function(xhr, status, error) {
          // 请求失败的处理逻辑
          console.error(error);
          this_.tip='账号名或密码错误'
        }
      });
    },
    sign(){
      // 先判断用户名和密码是否为空
      if(this.username === '' || this.password === ''){
        // 如果为空的弹出提示
        this.flag='false'
        this.tip='用户名和密码为空'
      }
      this.getLogin();
    }
  }
}
</script>

// scoped 样式的作用域在本组件中
<style scoped>
.box1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url("~@/assets/background10.jpg");
  background-size: cover;
  background-position: center;
}

.login-container {
  width: 400px;
  padding: 20px;
  //background-color: #f5f5f5;
  border-radius: 5px;
  //background-image: url("~@/assets/bjt.jpg");
  background-size: cover;
  background-position: center;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
}

label {
  display: inline-block;
  width: 60px;
  font-weight: bold;
}

input {
  width: 200px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  font-size: medium;
  padding: 5px 10px;
  //background-color: rgb(37 108 172/0%);
  border: none;
  border-radius: 5px;
  //color: black;
  cursor: pointer;
}

.button-container {
  text-align: center;
}

.tip-style {
  text-align: center;
  color: red;
}
.el-header {
  background-color: rgb(100 84 84 / 0%)!important;
  color: #fff;
  padding: 0;
  height: 60px;
}
.el-main {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
