<template>
  <div>
    <el-upload
        class="upload-demo"
        action="https://zhitotech.com/dhl_logistics_applet/manager/order/uploadLogisticsImage"
        :on-success="handleSuccess"
        :on-remove="handleRemove"
        :file-list="fileList"
    >
      <el-button size="small" type="primary">点击上传</el-button>
      <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
    </el-upload>

    <el-button v-if="fileList.length > 0" size="small" type="success" @click="handleDownload">下载PDF</el-button>
  </div>
</template>

<script>
export default {
  name: 'LogisticsWeb',
  data() {
    return {
      fileList: [],
    };
  },
  methods: {
    async handleSuccess(response, file, fileList) {
      // 上传成功的回调函数
      console.log('上传成功的图片地址:', response.coverImageFileName);
      this.fileList = fileList.map(file => ({
        url: response.coverImageFileName, // 使用上传成功返回的图片地址
        name: file.name,
      }));
    },
    handleRemove(file, fileList) {
      // 移除文件的回调函数
      this.fileList = fileList.filter(f => f !== file);
    },
    async handleDownload() {
      // 下载处理后的 PDF 文件
      if (this.fileList.length > 0) {
        const imageUrl = this.fileList[0].url; // 假设只处理第一个上传的图片
        try {
          // 向后端发送请求，传递 imageUrl 参数，获取处理后的 PDF 文件地址和文件名
          const response = await this.$axios.post('https://zhitotech.com/dhl_logistics_applet/manager/order/getPdfAddress',
              {image: imageUrl},
              {responseType: 'arraybuffer'} // 设置响应类型为 arraybuffer
          );
          const pdfData = response.data; // 直接使用 response.data 获取 PDF 数据
          const contentDisposition = response.headers['content-disposition'];
          let fileName = ''; // 从响应头中获取文件名
          const regex = /filename="([^"]+)"/;
          const match = regex.exec(contentDisposition);
          if (match) {
            fileName = match[1];
          }
          console.log("文件名：" + fileName);
          console.log("PDF 文件数据：", pdfData); // 在控制台中打印 PDF 数据

          // 调用 downloadPdf 函数下载 PDF
          this.downloadPdf(pdfData, fileName);
        } catch (error) {
          console.error('请求处理 PDF 文件地址失败：', error);
          this.$message.error('请求处理 PDF 文件地址失败');
        }
      } else {
        this.$message.error('请先上传图片');
      }
    },
    downloadPdf(pdfData, fileName) {
      const blob = new Blob([pdfData], { type: 'application/pdf' });

      // 使用 FileReader 对象读取 Blob 数据
      const reader = new FileReader();
      reader.onload = () => {
        // 创建一个隐藏的 a 标签并设置下载链接
        const link = document.createElement('a');
        link.href = reader.result;
        link.setAttribute('download', fileName); // 设置文件名为后台返回的文件名
        link.style.display = 'none';

        // 将 a 标签添加到页面中
        document.body.appendChild(link);

        // 触发 a 标签的点击事件
        link.click();

        // 清理 DOM
        document.body.removeChild(link);
      };
      reader.readAsDataURL(blob);
    }

  },
};
</script>

<style>
.upload-demo {
  display: inline-block;
  margin-bottom: 20px;
}
</style>
