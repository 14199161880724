<template>
  <div class="home">
    <el-container>
      <el-header>
        <!-- 顶部导航栏 -->
        <el-menu mode="horizontal" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
          <div style="float: left;margin-left: 20px;">
            <span style="font-size: x-large;font-weight: 800;">物流管理系统</span>
          </div>
          <div style="float: right;margin-right: 20px;">
            <el-dropdown>
            <span class="el-dropdown-link">
               {{$wlglUser.realName}}
                <i class="el-icon-user"></i>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item><div @click="getOutLogin"> <span>退出登录</span>
                  <i class="el-icon-switch-button"></i></div></el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>


          </div>

        </el-menu>
      </el-header>

      <el-container>
        <el-aside width="200px"  class="aside" ref="aside">
          <!-- 左侧菜单栏 -->
          <el-menu @select="handleMenuSelect" :default-active="selectedMenuItem" :unique-opened="true" background-color="#f8f8f9" text-color="#545c64" active-text-color="#409EFF">
            <el-menu-item index="ddxqlb" style="text-align: left;" v-if="user.status != 7">
              <i class="el-icon-menu"></i>订单详情列表</el-menu-item>
<!--            <el-menu-item index="ddxxdr">
              <i class="el-icon-upload"></i>订单信息导入</el-menu-item>-->
            <el-menu-item index="qxgl" style="text-align: left;" v-if="user.status != 7">
              <i class="el-icon-s-tools"></i>权限管理</el-menu-item>
            <el-menu-item index="wldgl" style="text-align: left;" v-if="user.status != 7">
              <i class="el-icon-s-tools"></i>物流单管理</el-menu-item>
            <!-- 其他菜单项 -->
            <el-menu-item index="offorder" style="text-align: left;" v-if="user.status != 7">
              <i class="el-icon-menu"></i>物流信息管理
            </el-menu-item>
            <el-menu-item index="offordercus" style="text-align: left;" v-if="user.status == 7">
              <i class="el-icon-menu"></i>物流信息查询
            </el-menu-item>

            <el-menu-item index="jdorder" style="text-align: left;" v-if="user.status != 7">
              <i class="el-icon-truck"></i>京东物流信息管理(测试)
            </el-menu-item>
          </el-menu>
        </el-aside>

        <el-main>
          <div v-if="selectedMenuItem === 'ddxqlb'">
            <WlglDdxxlb/>
            <!-- 主页信息内容 -->
          </div>
<!--          <div v-else-if="selectedMenuItem === 'ddxxdr'">
            <WlglImport/>
            &lt;!&ndash; 关于信息内容 &ndash;&gt;
          </div>-->
          <div v-else-if="selectedMenuItem === 'qxgl'">
            <WlglQxgl/>
            <!-- 联系信息内容 -->
          </div>
          <div v-else-if="selectedMenuItem === 'wldgl'">
            <LogisticsWeb/>
            <!-- 联系信息内容 -->
          </div>

          <div v-else-if="selectedMenuItem === 'offorder'">
            <WlglOffOrder/>    <!-- 线下订单 -->
          </div>

          <div v-else-if="selectedMenuItem === 'offordercus'">
            <WlglOffOrderForCustom/>    <!-- 线下订单 -->
          </div>


          <div v-else-if="selectedMenuItem === 'jdorder'">
            <JDWlglOrder/>    <!-- JD订单 -->
          </div>
          

        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import WlglQxgl from '@/components/WlglQxgl.vue'
import LogisticsWeb from '@/components/LogisticsWeb.vue'
import WlglDdxxlb from '@/components/WlglDdxxlb.vue'
import WlglOffOrder from '@/components/WlglOffOrder.vue'
import WlglOffOrderForCustom from './WlglOffOrderForCustom.vue';
import JDWlglOrder from '@/components/JDWlglOrder.vue'


export default {
  name: 'WlglHome',
  components: {
    WlglDdxxlb,
    WlglQxgl,
    LogisticsWeb,
    WlglOffOrder,
    WlglOffOrderForCustom,
    JDWlglOrder
  },
  data() {
    return {
      user: {},
      selectedMenuItem:"",
    };
  },
  created() {
    this.user = this.$wlglUser;

  },
  mounted() {
    this.$nextTick(() => {
      this.setAsideHeight();
      window.addEventListener('resize', this.setAsideHeight); // 监听窗口大小变化
    });

    if(this.user.status == '7') {
      this.selectedMenuItem = "offordercus";
    } else{
      this.selectedMenuItem = "ddxqlb";
    }

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setAsideHeight); // 移除事件监听
    this.wlglUser = [];
  },
  methods:{
    setAsideHeight() {
      const windowHeight = window.innerHeight;
      /*this.$nextTick(() => {
        this.$refs.aside.style.height = windowHeight + 'px';
      });*/
    },
    handleMenuSelect(index) {
      this.selectedMenuItem = index;
    },
    getOutLogin(){
      this.$wlglUser = [];
      sessionStorage.setItem("token","")
      this.$router.push("/login")
    }
  }
}
</script>

<style>
.home {
  height: 100vh;
  margin-top: 0px;
}

.el-header {
  background-color: #545c64;
  color: #fff;
  padding: 0;
  height: 60px;
}

.el-menu {
  line-height: 60px;
}

.el-aside {
  background-color: #f8f8f9;
}

.statistics-container {
  margin: 20px;
  display: flex;
}

.statistics-item {
  flex: 1;
  padding: 20px;
  background-color: #fff;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.statistics-item-title {
  color: #333;
  font-size: 16px;
}

.statistics-item-value {
  color: #409EFF;
  font-size: 24px;
  margin-top: 5px;
}

.quick-actions-container {
  margin: 20px;
  text-align: right;
}
.aside{
  margin-top: 5px;
}
h2 {
  margin: 20px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #ffffff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>